// Core
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  ArticleCard,
  Breadbrumbs,
  Pagination,
} from "../../components/";
import Layout from "../../layout/";

class BlogTopicTemplate extends React.Component {
  render() {
    const articles = get(this, "props.data.allContentfulJournalArticle.edges");
    const topic = get(this, "props.data.contentfulJournalTopic");
    const { current_page, number_of_pages, pathname } = this.props.pageContext;

    return (
      <Layout
        seo={{
          pathname: pathname,
          title: topic.name,
        }}
      >
        <section className={styles.header}>
          <div className={styles.header__inner}>
            <h1>{topic.name}</h1>
          </div>

          <div
            className={[
              styles.header__background,
              "rellax rellax--background",
            ].join(" ")}
          >
            <GatsbyImage
              alt={topic.name}
              image={topic.image.gatsbyImageData}
              loading="eager"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </section>

        <section className="container pb100 pt20">
          <Breadbrumbs
            breadcrumbs={[
              {
                label: "Home",
                url: "/",
              },
              {
                label: "Journal",
                url: "/journal",
              },
              {
                label: topic.name,
              },
            ]}
          />

          <div className={styles["articles-grid"]}>
            {articles.map(({ node }) => {
              return (
                <ArticleCard
                  article={node}
                  key={node.slug}
                />
              );
            })}
          </div>

          {number_of_pages > 1 && (
            <div className="mt80">
              <Pagination
                current={current_page}
                pathname={pathname}
                total={number_of_pages}
              />
            </div>
          )}
        </section>
      </Layout>
    );
  }
}

export default BlogTopicTemplate;

export const pageQuery = graphql`
  query BlogTopicTemplateQuery($topic_slug: String!, $skip: Int!, $limit: Int!) {
    allContentfulJournalArticle(
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
      filter: { topic: { slug: { eq: $topic_slug } } }
    ) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(aspectRatio: 1.8, layout: FULL_WIDTH, placeholder: BLURRED)
          }
          topic {
            name
            slug
          }
          description {
            description
          }
          author {
            name
            slug
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    contentfulJournalTopic(slug: { eq: $topic_slug }) {
      name
      image {
        fixed(height: 628, width: 1200) {
          src
        }
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
